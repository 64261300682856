import CaretIcon from '../../assets/shapes/caret-right.svg';
import styled from '@emotion/styled';

const Caret = styled(CaretIcon)`
  &.backward {
    transform: rotate(180deg);
  }
  &.down {
    transform: rotate(90deg);
  }
  &.right {
    transform: rotate(-180deg)
  }
`;

export default Caret;