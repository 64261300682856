import React from "react";
import styled from '@emotion/styled';

import {
  white,
  blurple,
  mdYellow,
  mdPink,
} from '@sentry/static-shared/utils/css/colors';
import { mqMin, screenReadersOnly } from '@sentry/static-shared/utils/css';
import slugify from '@sentry/static-shared/utils/slugify';
import { floatingObject } from '@sentry/static-shared/utils/css/shadows';
import twitterLogo from '@sentry/static-shared/icons/twitter-x.svg';
import facebookLogo from '@sentry/static-shared/icons/facebook-logo.svg';
import hackerNewsLogo from '@sentry/static-shared/icons/hacker-news-logo.svg';
import linkedInLogo from '@sentry/static-shared/icons/linkedin-logo.svg';

const SocialShareWidget = (socialData) => {
  const { shareData: {
    siteUrl, title, twitterHandle, url
  } } = socialData;

  const twitterURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}%20${siteUrl}${url}&amp;rl=${siteUrl}${url}&amp;via=${twitterHandle}&amp;related=${twitterHandle}`;

  const facebookURL = `https://facebook.com/sharer.php?u=${siteUrl}${url}`;

  const hackerNewsURL = `http://news.ycombinator.com/submitlink?u=${siteUrl}${url}&amp;t=${encodeURIComponent(
    title
  )}`;

  const linkedInURL = `http://www.linkedin.com/shareArticle?mini=true&url=${siteUrl}${url}&title=${encodeURIComponent(title)}&source=blog.sentry.io`;

  return (
    <StyledSocialWrapper>
      <StyledHeader>Share</StyledHeader>
      <StyledSocialWidget>
        {[
          {
            name: 'Twitter',
            src: twitterLogo,
            linkTo: twitterURL,
            color: blurple,
          },
          {
            name: 'Facebook',
            src: facebookLogo,
            linkTo: facebookURL,
            color: blurple,
          },
          {
            name: 'HackerNews',
            src: hackerNewsLogo,
            linkTo: hackerNewsURL,
            color: blurple,
          },
          {
            name: 'LinkedIn',
            src: linkedInLogo,
            linkTo: linkedInURL,
            color: blurple,
          }
        ].map(props => (
          <LogoButton key={slugify(props.linkTo)} {...props} />
        ))}
      </StyledSocialWidget>
    </StyledSocialWrapper>
  )
}

export default SocialShareWidget;

const LogoButton = ({ name, src, linkTo, color }) => {
  return (
    <StyledHitPlate href={linkTo}>
      <StyledLogoWrap>
        <StyledLogo as={src} color={color} />
        <StyledSocialLabel>Share on {name}</StyledSocialLabel>
      </StyledLogoWrap>
    </StyledHitPlate>
  );
};

const StyledSocialWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSocialWidget = styled.div`
  display: flex;
  gap: 2rem;
`;

const StyledHeader = styled.p`
font-size: 0.875rem;
text-transform: uppercase;
`;

const StyledLogoWrap = styled.div`
  position: absolute;
  padding: 0.25rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.svg`
  position: relative;
  flex-shrink: 0;
  transition: margin-right 0.2s, margin-left 0.2s;
  height: 100%;
  width: 100%;
  height: auto;
  margin-left: 0;
  color: ${({ color }) => color};
`;

const StyledSocialLabel = styled.span`
  ${screenReadersOnly}
`;

const StyledHitPlate = styled.a`
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;

  &:after {
    display: block;
    content: '';
    padding-top: 100%;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
    border-radius: 0.5rem;
    transition: box-shadow 0.3s;
    will-change: box-shadow;
  }

  &:hover {
    text-decoration: none;
    z-index: 10;

    &:before {
      box-shadow: ${floatingObject};
      background-color: ${white};
    }
  }

  &:active,
  &:focus {
    &:before {
      box-shadow: -0.09375rem -0.09375rem 0 0.09375rem ${mdYellow},
        0 0 0 0.1875rem ${mdPink};
      background-color: ${white};
    }
  }
`;
