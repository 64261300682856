import React, { useEffect, useRef } from 'react';
import loadable from '@loadable/component';
import styled from '@emotion/styled';
import CopyIcon from '@sentry/static-shared/icons/copy.svg';
import { Plausible } from '@sentry/static-shared/utils/plausible.js';
import useCopyToClipboard from '@sentry/static-shared/utils/useCopyToClipboard.ts';
import {
  mdPink,
  white,
} from '@sentry/static-shared/utils/css/colors';
import { screenReadersOnly } from '../utils/css';

const DeferrableHighlight = loadable(
  async () => await import(`./highlight-deferred`)
);


/*
  Source code highlighting using prismjs. Because Prism requires supporting many
  languages, we defer the load so it can be bundled separately.
*/
function Highlight({ language, children }) {
  const [value, copy] = useCopyToClipboard()
  const tooltipMessage = value ? 'Copied!' : 'Click to Copy';
  const [toggleIcon] = [!(!!value)];
  const [toggleLabel] = [!!value];
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef?.current && typeof Prism !== 'undefined') {
      Prism?.highlightAllUnder(containerRef.current);
    }
  }, [children]);

  const handleCopyEvent = () => {
    copy(children);
    Plausible('copy sentry code');
  }

  return (
    <div role='button' tabIndex="0" onKeyDown={handleCopyEvent} onClick={handleCopyEvent} title={tooltipMessage} ref={containerRef}>
      <ScreenReaderSpan>{'Click to Copy'}</ScreenReaderSpan>
      <ButtonOverlay>
        <StyledLabel visibility={toggleLabel}>{tooltipMessage}</StyledLabel>
        <div>
          <StyledIcon visibility={toggleIcon} StyledIcon as={CopyIcon}/>
        </div>
      </ButtonOverlay>
      <pre className={`language-${language}`}>
        <Code className={`language-${language}`}>
          <DeferrableHighlight fallback={children}>
            <code className={`language-${language}`}>
              {children}
            </code>
          </DeferrableHighlight>
        </Code>
      </pre>
    </div>
  );
}

const ScreenReaderSpan = styled.span`
  ${screenReadersOnly}
`;

const ButtonOverlay = styled.div`
  float: right;
  position: relative;
  right: 2rem;
  top: 0.75rem;
  cursor: pointer;
  z-index: 9;
`;

const StyledLabel = styled.label`
  position: absolute;
  z-index: 10;
  float: right;
  top: 2px;
  left: -2rem;
  align-self: flex-start;
  color: ${white};
  display: ${props => props.visibility? 'flex': 'none'};
  background: #160f24;
  box-shadow: 0 0 7px 4px #160f24, 0 0 8px 3px transparent;
`;

const StyledIcon = styled.svg`
position: absolute;
z-index: 10;
height: 20px;
width: 20px;
fill: white;
align-self: flex-end;
display: ${props => props.visibility? 'flex': 'none'};
background: #160f24;
box-shadow: 7px 0 0 5px #160f24, 0 0 15px 9px transparent;
`;

const Code = styled.div`
position: relative;
color: inherit;
transition: box-shadow 0.3s;
will-change: box-shadow;
outline: 0;
display: flex;
flex-direction: column;
z-index: 9;

&:hover,
&:focus {
  cursor: pointer;
}

&:hover ${StyledIcon},
&:focus ${StyledIcon} {
  fill: ${mdPink};
  cursor: pointer;
}
`;


export default Highlight;