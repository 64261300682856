export const Plausible = (plausibleEvent) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const PageTitle = document.title;
        const QueryString = window.location?.search;
        const UrlParams = new URLSearchParams(QueryString);
        const MediumUTM = UrlParams.get('utm_medium');
        const SourceUTM = UrlParams.get('utm_source');
        const CampaignUTM = UrlParams.get('utm_campaign');
        const TermUTM = UrlParams.get('utm_term');
        const ContentUTM = UrlParams.get('utm_content');

        window.plausible = window.plausible || function() { 
            (window.plausible.q = window.plausible.q || []).push(arguments) 
        }
        if(window.plausible) {
            plausible(plausibleEvent, {props: 
                {
                    page: PageTitle,
                    medium: MediumUTM ? MediumUTM : '',
                    source: SourceUTM ? SourceUTM : '',
                    campaign: CampaignUTM ? CampaignUTM : '',
                    term: TermUTM ? TermUTM : '',
                    content: ContentUTM ? ContentUTM : ''
                }
            });
        }
    }
}