import React, { useState, useEffect } from 'react';
import ResponsiveEmbed from './ResponsiveEmbed';
import PropTypes from 'prop-types';
import axios from 'axios';
import defaultImgXLJpg from '../images/default-thumbnails-vimeo/sentrydefault_1920x1080.jpg';
import defaultImgXLWebp from '../images/default-thumbnails-vimeo/sentrydefault_1920x1080.webp';
import defaultImgLGJpg from '../images/default-thumbnails-vimeo/sentrydefault_1280x720.jpg';
import defaultImgLGWebp from '../images/default-thumbnails-vimeo/sentrydefault_1280x720.webp';
import defaultImgMDJpg from '../images/default-thumbnails-vimeo/sentrydefault_480x270.jpg';
import defaultImgMDWebp from '../images/default-thumbnails-vimeo/sentrydefault_480x270.webp';
import defaultImgSMJpg from '../images/default-thumbnails-vimeo/sentrydefault_320x180.jpg';
import defaultImgSMWebp from '../images/default-thumbnails-vimeo/sentrydefault_320x180.webp';
import defaultImgXSJpg from '../images/default-thumbnails-vimeo/sentrydefault_120x68.jpg';
import defaultImgXSWebp from '../images/default-thumbnails-vimeo/sentrydefault_120x68.webp';

const VimeoEmbed = ({ id, className, showVideo, isInViewport, embedTitle }) => {
  const autoplay = showVideo ? true : false;
  const inRange = isInViewport ? true : false;
  let url = `https://player.vimeo.com/video/${
    id.toString().split('/')[0]
  }?autoplay=1&dnt=1${autoplay ? `&background=1` : ``}`;
  const [thumbnailUrl, setThumbnailUrl] = useState(null);

  useEffect(() => {
    (async () => {
      const apiUrl = `https://vimeo.com/api/oembed.json?url=`;
      const vimeoUrl = `https://vimeo.com/${id}`;

      let { data } = await axios.get(`${apiUrl}${vimeoUrl}`);

      if (data?.thumbnail_url) {
        setThumbnailUrl(data.thumbnail_url.replace(/_\d+(x\d+)?$/, ''));
      } else {
        setThumbnailUrl('default');
      }
      
    })();
  }, []);

  const preconnectUrls = [
    'https://player.vimeo.com',
    'https://i.vimeocdn.com',
    'https://f.vimeocdn.com',
    'https://fresnel.vimeocdn.com',
  ];


  return thumbnailUrl ? (
    <ResponsiveEmbed
      url={url}
      thumbnails={[
        {
          url: thumbnailUrl !== 'default' ?  `${thumbnailUrl}_120x68.jpg` : defaultImgXSJpg,
          size: 120,
        },
        {
          url: thumbnailUrl !== 'default' ?  `${thumbnailUrl}_320x180.jpg` : defaultImgSMJpg,
          size: 320,
        },
        {
          url: thumbnailUrl !== 'default' ?  `${thumbnailUrl}_480x270.jpg` : defaultImgMDJpg,
          size: 480,
        },
        {
          url: thumbnailUrl !== 'default' ?  `${thumbnailUrl}_1280x720.jpg` : defaultImgLGJpg,
          size: 1280,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_1920x1080.jpg` : defaultImgXLJpg,
          size: 1920,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_120x68.webp` : defaultImgXSWebp,
          size: 120,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_320x180.webp` : defaultImgSMWebp,
          size: 320,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_480x270.webp` : defaultImgMDWebp,
          size: 480,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_1280x720.webp` : defaultImgLGWebp,
          size: 1280,
        },
        {
          url: thumbnailUrl !== 'default' ? `${thumbnailUrl}_1920x1080.webp` : defaultImgXLWebp,
          size: 1920,
        },
      ]}
      preconnectUrls={preconnectUrls}
      className={className}
      showVideo={autoplay}
      isInViewport={inRange}
      {...embedTitle ? `embedTitle=${embedTitle}` : ''}
    />
  ) : null;
};

export default VimeoEmbed;

VimeoEmbed.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  thumbnailId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  showVideo: PropTypes.bool,
  embedTitle: PropTypes.string
};
