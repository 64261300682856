import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import defaultImgXLJpg from '../images/default-thumbnails-vimeo/sentrydefault_1920x1080.jpg';

const YouTubeEmbed = ({ id, className, title, cta }) => {
  const link = `https://youtube.com/watch?v=${id}`;
  const altTitle = title || 'Sentry Video';
  const ytCTA = cta || 'Watch on YouTube';

  return (
    <div class={className}>
      <StyledYouTubeLink href={link} target="_BLANK" rel='nofollow noopener'>
        <img
          src={defaultImgXLJpg}
          alt={altTitle}
        />
        <p>{ytCTA}</p>
      </StyledYouTubeLink>
    </div>
  );
};

export default YouTubeEmbed;

YouTubeEmbed.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  cta: PropTypes.string
};

const StyledYouTubeLink = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;