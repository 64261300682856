import React, { useState } from 'react';
import styled from '@emotion/styled';
import BasicAccordionCard from '@sentry/static-shared/components/molecules/AccordionCard';

const FaqAccordion = ({ faqs, mode, multiOpen }) => {
  const [selectedCollapses, setSelectedCollapses] = useState([!multiOpen ? faqs[0]?.slug : '']);
  const updateCollapse = (value) => {
    if (multiOpen) {
      const updated = selectedCollapses?.includes(value)
        ? selectedCollapses?.filter(key => key !== value)
        : [...(new Set([...selectedCollapses, value]))]

        setSelectedCollapses(updated);
    } else {
      setSelectedCollapses([value])
    }
  };
  const mapFaqData = ({ question, mdx, answer, slug }) => ({
    title: question,
    key: slug,
    id: slug,
    answer: mdx ?? answer
  });
  const getSelectedCollapse = ({ id }) => selectedCollapses?.filter((key) => id === key)?.pop()

  return (
      <AccordionGroup>
        {
          faqs
          .map(mapFaqData)
          .map(data => <BasicAccordionCard 
            data={data}
            mode={mode}
          />)
        }
      </AccordionGroup>
  );
};

const Container = styled.div`
`;

const AccordionGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 0.5rem;
  height: 100%;
`;

export default FaqAccordion;