import React from "react";
import styled from '@emotion/styled';

import {
    blurple,
    richBlack,
} from '@sentry/static-shared/utils/css/colors';
import Button from "@sentry/static-shared/components/Button";
import defaultImage from '../../assets/meta/default.png';

const PostResourceCTA = ({resourceData, showButton = false, showExcerpt = true}) => {
    const { title, excerpt } = resourceData;
    let tileImage = defaultImage;
    let link;

    if (resourceData.metaImage) {
        tileImage = resourceData.metaImage.file.url;
        link = `/${resourceData.slug}`;
    }

    if (resourceData.coverImage) {
        tileImage = resourceData.coverImage.file.url;
        link = resourceData.link;
    }

    return (
        <StyledResourceCTA href={link}>
            <img src={tileImage} />
            <h3>{title}</h3>
            {excerpt && showExcerpt && <p>{excerpt.excerpt}</p>}
            {showButton && <StyledButton to={link} variant="silent" redesign compensate="left">Read More</StyledButton>}
        </StyledResourceCTA>
    );
};

export default PostResourceCTA;

const StyledResourceCTA = styled.a`
    color: ${richBlack};

    img {
        margin-bottom: 1rem;
        width: 100%;
    }

    h3 {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.875rem;
    }

    p:not(:first-child) {
        margin-bottom: 0;
    }
`;

const StyledButton = styled(Button)`
    &.btn-new.silent {
        color: ${blurple};
    }
`;