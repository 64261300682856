import React from 'react';

import Jumbotron from '@sentry/static-shared/components/Jumbotron';
import GifStyleVideo from '@sentry/static-shared/components/GifStyleVideo';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BlogHero = ({ heroImage, heroVideo, blogTitle }) => (
  <>
    {heroVideo &&
      <Jumbotron overlap={false}>
        <GifStyleVideo
          video={heroVideo}
          poster={heroImage}
          fit="cover"
        />
      </Jumbotron>
    }
    {!heroVideo &&
      <p>
        <GatsbyImage image={getImage(heroImage)} alt={blogTitle} />
      </p>}
  </>
)

export default BlogHero;